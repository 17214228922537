function setEqualHeight() {
    $('.grid-menu-item-022-top .menu-item-row-element.img').matchHeight()
    $('.grid-menu-item-022-top .menu-item-title').matchHeight()
    $(
        '.grid-menu-item-022-top .content-container > .menu-item-content'
    ).matchHeight()
    $(
        '.grid-menu-item-022-bottom.gallery-001 .menu-item-row-element .content-container'
    ).matchHeight()
    $('#zadnji-projekti').matchHeight({ target: $('#kontakt-modro-ozadje') })
    $('#zadnji-projekti-slika').matchHeight({
        target: $('#zadnji-projekti-projekt')
    })
}
$(window).on('load', function () {
    setEqualHeight()
})
$(window).on('resize', function () {
    // setEqualHeight();
    $.fn.matchHeight._update()
})

$('#main-menu-state').on('change', function (e) {
    var $menu = $('#main-menu')
    var maxHeight,
        skelBreakpoints = skel.vars.stateId
    if (this.checked) {
        $menu.hide().slideDown(250, function () {
            $menu.css('display', '')
            if (skelBreakpoints.search('/medium') > -1) {
                maxHeight =
                    0.95 *
                    ($(window).height() -
                        ($('#main-menu').offset().top - $(window).scrollTop()))
                $menu.css('max-height', maxHeight + 'px')
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css('height', maxHeight + 'px')
                }
            }
        })
    } else {
        $menu.show().slideUp(250, function () {
            $menu.css('display', '')
            $menu.css('max-height', '')
            $menu.css('height', '')
        })
    }
})

$(window).bind('scroll', function () {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 40,
        scroll_limit = 140,
        initial_height = 100,
        target_height = 60,
        initial_padding = 20,
        target_padding = 5,
        initial_margin_top = 0,
        target_margin_top = -40,
        margin,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case 'xxxlarge':
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 60;
            initial_padding = 20;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case 'xxlarge':
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 60;
            initial_padding = 20;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case 'large':
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 60;
            initial_padding = 20;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case 'medium':
            scroll_limit_1 = 40;
            scroll_limit = 100;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case 'small':
            scroll_limit_1 = 40;
            scroll_limit = 100;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            break;
        case 'xsmall':
            scroll_limit_1 = 40;
            scroll_limit = 100;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            break;
        default:
            ; scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 60;
            initial_padding = 20;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            $('#header-wrapper').css('margin-top', '')
            if ($('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').removeClass('fixed')
            }
            margin_top = initial_margin_top
            padding = initial_padding
            logo_height = initial_height
        } else if (
            $(window).scrollTop() > 0 &&
            $(window).scrollTop() <= scroll_limit_1
        ) {
            $('#header-wrapper').css('margin-top', -$(window).scrollTop() + 'px')
            margin_top = initial_margin_top - $(window).scrollTop()
            padding = initial_padding
            logo_height = initial_height
            if ($('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').removeClass('fixed')
            }
        } else if (
            $(window).scrollTop() > scroll_limit_1 &&
            $(window).scrollTop() <= scroll_limit
        ) {
            $('#header-wrapper').css('margin-top', -scroll_limit_1 + 'px')
            margin_top = target_margin_top
            // padding = initial_padding * ((scroll_limit - scroll_limit_1) - ($(window).scrollTop() - scroll_limit_1)) / (scroll_limit - scroll_limit_1);
            padding =
                initial_padding -
                ((initial_padding - target_padding) *
                    ($(window).scrollTop() - scroll_limit_1)) /
                (scroll_limit - scroll_limit_1)
            logo_height =
                initial_height -
                ((initial_height - target_height) *
                    ($(window).scrollTop() - scroll_limit_1)) /
                (scroll_limit - scroll_limit_1)
            if ($('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').removeClass('fixed')
            }
            /*
                  if (current_breakpoint_num > 3) {
                      $("#logo-top").show();
                      $("#logo-scroll").hide();
                  }
                  */
        } else {
            $('#header-wrapper').css('margin-top', -scroll_limit_1 + 'px')
            margin_top = target_margin_top
            padding = target_padding
            logo_height = target_height
            if (!$('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').addClass('fixed')
            }
            /*
                  if (current_breakpoint_num > 3) {
                      $("#logo-top").hide();
                      $("#logo-scroll").show();
                  }
                  */
        }
        $('#logo a').css('padding-top', padding + 'px')
        $('#logo a').css('padding-bottom', padding + 'px')
        $('#logo a').css('height', logo_height + 'px')
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $('#logo a').css('padding-top', '')
        $('#logo a').css('padding-bottom', '')
        $('#logo a').css('height', '')
        if ($(window).scrollTop() === 0) {
            $('#header-wrapper').css('margin-top', '')
            if ($('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').removeClass('fixed')
            }
        } else if (
            $(window).scrollTop() > 0 &&
            $(window).scrollTop() <= scroll_limit_1
        ) {
            $('#header-wrapper').css('margin-top', -$(window).scrollTop() + 'px')
            if ($('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').removeClass('fixed')
            }
        } else if (
            $(window).scrollTop() > scroll_limit_1 &&
            $(window).scrollTop() <= scroll_limit
        ) {
            $('#header-wrapper').css('margin-top', -scroll_limit_1 + 'px')
            if ($('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').removeClass('fixed')
            }
        } else {
            $('#header-wrapper').css('margin-top', -scroll_limit_1 + 'px')
            if (!$('#header-wrapper').hasClass('fixed')) {
                $('#header-wrapper').addClass('fixed')
            }
        }
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }
})
